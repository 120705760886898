import React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useAuth } from '../../context/Auth'

const AuthRoute = ({ children, ...rest }) => {
  const { pathname, search } = useLocation()
  const { authenticated } = useAuth()
  return (
    <Route {...rest}>
      {authenticated ? (
        children
      ) : (
        <Redirect to={`/login?redirect=${pathname}${search}`} />
      )}
    </Route>
  )
}

export default AuthRoute
