import React from 'react'
import classNames from 'classnames'
import styles from './ButtonIcon.module.css'

const ButtonIcon = ({
  children,
  className,
  color = 'primary',
  size = 'medium',
  disabled = false,
  onClick,
  type = 'button',
  id,
  label,
  ...props
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.stopPropagation()
      if (onClick) onClick()
    }
  }
  return (
    <button
      id={id}
      aria-label={label}
      type={type}
      tabIndex={0}
      className={classNames(className, styles.buttonIcon, {
        [styles[`buttonIcon-${color}`]]: true,
        [styles[`buttonIcon-${size}`]]: Boolean(size),
      })}
      disabled={disabled}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      {...props}
    >
      {children}
    </button>
  )
}

export default ButtonIcon
