import React from 'react'
import classNames from 'classnames'
import { Link as RouterLink } from 'react-router-dom'
import styles from './Link.module.css'

const Link = ({ children, className, external = false, ...props }) => {
  const Component = external ? 'a' : RouterLink
  return (
    <Component
      className={classNames(className, styles.link, {
        [styles['link-external']]: external,
      })}
      {...props}
    >
      {children}
    </Component>
  )
}

export default Link
