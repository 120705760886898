import React from 'react'
import styles from './NavItem.module.css'

const NavItem = ({ children, onClick, ...props }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.stopPropagation()
      if (onClick) onClick()
    }
  }
  return (
    <li
      {...props}
      className={styles.item}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handleKeyDown}
    >
      {children}
    </li>
  )
}

export default NavItem
