import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from './Auth'

const AppProviders = ({ children }) => {
  return (
    <AuthProvider>
      <Router>{children}</Router>
    </AuthProvider>
  )
}

export default AppProviders
