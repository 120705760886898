import React from 'react'
import styles from './Nav.module.css'

const Nav = ({ children, logo, ...props }) => {
  return (
    <nav className={styles.nav} {...props}>
      {logo}
      <ul className={styles.list}>{children}</ul>
    </nav>
  )
}

export default Nav
