import React, { Suspense } from 'react'
import { Switch } from 'react-router-dom'
import loadable from '@loadable/component'
import AuthRoute from './components/AuthRoute'
import NoAuthRoute from './components/NoAuthRoute'
import Spinner from './components/Spinner'

const Home = loadable(() => import('./pages/Home'))
const NotFound = loadable(() => import('./pages/NotFound'))
const Login = loadable(() => import('./pages/Login'))
const Signup = loadable(() => import('./pages/Signup'))
const Todo = loadable(() => import('./pages/Todo'))

const Routes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <AuthRoute exact path="/">
          <Home />
        </AuthRoute>
        <AuthRoute exact path="/view/:id">
          <Todo />
        </AuthRoute>
        <NoAuthRoute exact path="/login">
          <Login />
        </NoAuthRoute>
        <NoAuthRoute exact path="/signup">
          <Signup />
        </NoAuthRoute>
        <NoAuthRoute>
          <NotFound />
        </NoAuthRoute>
      </Switch>
    </Suspense>
  )
}

export default Routes
