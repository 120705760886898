const stage = {
  s3: {
    REGION: 'us-east-2',
    BUCKET: 'zdtdev-todos-api-stage-attachmentsbucket-tluwb25d4coi',
  },
  apiGateway: {
    REGION: 'us-east-2',
    URL: 'https://api-stage.nothingtoitapp.com',
  },
  cognito: {
    REGION: 'us-east-2',
    USER_POOL_ID: 'us-east-2_X2TDjt1Be',
    APP_CLIENT_ID: '7dh4e0apa5la1agg2546bhek98',
    IDENTITY_POOL_ID: 'us-east-2:a7a1b2c4-b40f-4ecc-888b-9cc292390dfa',
  },
}

const prod = {
  s3: {
    REGION: 'us-east-2',
    BUCKET: 'zdtdev-todos-api-prod-attachmentsbucket-1m3jdz5lj7wxx',
  },
  apiGateway: {
    REGION: 'us-east-2',
    URL: 'https://api.nothingtoitapp.com',
  },
  cognito: {
    REGION: 'us-east-2',
    USER_POOL_ID: 'us-east-2_5zAkfYWSQ',
    APP_CLIENT_ID: '5unp7uni2b0ql28cnqkdn45ik0',
    IDENTITY_POOL_ID: 'us-east-2:31aeeb79-cce3-4737-a6e7-18b954ba37d0',
  },
}

const config = process.env.REACT_APP_STAGE === 'production' ? prod : stage

export default {
  ...config,
}
