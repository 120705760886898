import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import styles from './Button.module.css'

const Button = ({
  children,
  className,
  icon = null,
  color = 'primary',
  size = 'medium',
  disabled = false,
  loading = false,
  fullWidth = false,
  onClick,
  type = 'button',
  id,
  ...props
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.stopPropagation()
      if (onClick) onClick()
    }
  }
  return (
    <button
      id={id}
      type={type}
      tabIndex={0}
      className={classNames(className, styles.button, {
        [styles[`button-${color}`]]: true,
        [styles[`button-${size}`]]: Boolean(size),
        [styles['button-fullWidth']]: fullWidth,
      })}
      disabled={disabled || loading}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      {...props}
    >
      {loading ? (
        <FontAwesomeIcon icon={faCircleNotch} spin />
      ) : (
        <>
          {icon}
          {children && <span>{children}</span>}
        </>
      )}
    </button>
  )
}

export default Button
