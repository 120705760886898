import React from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import onError from '../../util/onError'
import Fallback from './Fallback'

const ErrorBoundary = ({ children }) => {
  return (
    <ReactErrorBoundary FallbackComponent={Fallback} onError={onError}>
      {children}
    </ReactErrorBoundary>
  )
}

export default ErrorBoundary
