import React from 'react'
import Button from '../Button'
import styles from './Fallback.module.css'

const Fallback = ({ error, componentStack, resetErrorBoundary }) => {
  return (
    <div role="alert" className={styles.wrapper}>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <pre>{componentStack}</pre>
      <Button onClick={resetErrorBoundary}>Try again</Button>
    </div>
  )
}

export default Fallback
