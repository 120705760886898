import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Link from './Link'
import styles from './LinkBack.module.css'

const LinkBack = ({ to = '/' }) => {
  return (
    <Link className={styles.linkBack} to={to}>
      <FontAwesomeIcon icon={faArrowLeft} /> Back
    </Link>
  )
}

export default LinkBack
