import React from 'react'
import classNames from 'classnames'
import styles from './Spinner.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Spinner = ({ className, fullScreen, ...props }) => {
  return (
    <div
      className={classNames(className, styles.wrapper, {
        [styles['wrapper-fullScreen']]: fullScreen,
      })}
      {...props}
    >
      <FontAwesomeIcon icon={faSpinner} spin size="3x" />
    </div>
  )
}

export default Spinner
