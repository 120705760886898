import React, { forwardRef } from 'react'
import classNames from 'classnames'
import styles from './Grid.module.css'

const Grid = forwardRef(
  (
    {
      component: Component = 'div',
      container = false,
      item = false,
      width = 12,
      align = 'start',
      className,
      ...props
    },
    ref
  ) => {
    return (
      <Component
        {...props}
        ref={ref}
        className={classNames(className, {
          [styles[`grid-container`]]: container,
          [styles['grid-item']]: item,
          [styles[`grid-width-${width}`]]: width !== null && item,
          [styles[`grid-container-${align}`]]: Boolean(align) && container,
        })}
      />
    )
  }
)

export default Grid
