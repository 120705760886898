import Auth from '@aws-amplify/auth'
import Storage from '@aws-amplify/storage'
import API from '@aws-amplify/api'
import config from './config'

const awsConfig = {
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    level: 'private',
  },
  API: {
    endpoints: [
      {
        name: 'todos',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
}

const configure = () => {
  Auth.configure(awsConfig)
  Storage.configure(awsConfig)
  API.configure(awsConfig)
}

export default configure
