import React, { createContext, useState, useContext } from 'react'
import Auth from '@aws-amplify/auth'
import onError from '../util/onError'

const AuthContext = createContext()

export const AuthProvider = (props) => {
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [authenticated, setAuthenticated] = useState(false)

  const signup = async (email, password) => {
    try {
      const newUser = await Auth.signUp({
        username: email,
        password,
      })
      return newUser
    } catch (e) {
      onError(e)
      if (e.name === 'UsernameExistsException') {
        try {
          const newUser = await Auth.resendSignUp(email)
          return newUser
        } catch (ex) {
          onError(ex)
        }
      }
    }
  }

  const confirmSignup = async (email, password, confirmationCode) => {
    try {
      await Auth.confirmSignUp(email, confirmationCode)
      await Auth.signIn(email, password)

      setAuthenticated(true)
    } catch (e) {
      onError(e)
      if (
        e.name === 'NotAuthorizedException' &&
        e.message === 'User cannot be confirmed. Current status is CONFIRMED.'
      ) {
        try {
          await Auth.signIn(email, password)
          setAuthenticated(true)
        } catch (e) {
          onError(e)
        }
      }
    }
  }

  const login = async (email, password) => {
    try {
      await Auth.signIn(email, password)
      setAuthenticated(true)
    } catch (e) {
      onError(e)
    }
  }
  const logout = async () => {
    await Auth.signOut()
    setAuthenticated(false)
  }
  const loadAuth = async () => {
    try {
      await Auth.currentSession()
      setAuthenticated(true)
    } catch (e) {
      if (e !== 'No current user') {
        onError(e)
      }
    }

    setIsAuthenticating(false)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticating,
        authenticated,
        signup,
        confirmSignup,
        login,
        logout,
        loadAuth,
      }}
      {...props}
    />
  )
}

export const useAuth = () => useContext(AuthContext)
