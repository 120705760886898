import * as Sentry from '@sentry/browser'

const isLocal = process.env.NODE_ENV !== 'production'

export const initSentry = () => {
  if (isLocal) {
    return
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  })
}

export const logError = (error, errorInfo = null) => {
  if (isLocal) {
    return
  }

  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo)
    Sentry.captureException(error)
  })
}
