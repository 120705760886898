import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../../context/Auth'

const decodeQueryString = (name, url = window.location.href) => {
  name = name.replace(/[[]]/g, '\\$&')

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i')
  const results = regex.exec(url)

  if (!results) {
    return null
  }
  if (!results[2]) {
    return ''
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const NoAuthRoute = ({ children, ...rest }) => {
  const redirect = decodeQueryString('redirect')
  const { authenticated } = useAuth()
  return (
    <Route {...rest}>
      {!authenticated ? children : <Redirect to={!redirect ? '/' : redirect} />}
    </Route>
  )
}

export default NoAuthRoute
