import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './App.module.css'
import Routes from './Routes'
import Link from './components/Link'
import Grid from './components/Grid'
import { useAuth } from './context/Auth'
import Nav from './components/Nav'
import { ReactComponent as Logo } from './logo.svg'

const App = () => {
  const history = useHistory()
  const { isAuthenticating, authenticated, logout, loadAuth } = useAuth()
  const handleLogout = async () => {
    await logout()
    history.push('/login')
  }
  useEffect(() => {
    loadAuth()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    !isAuthenticating && (
      <>
        <a className={styles.skipLink} href="#main-content">
          Skip to main content
        </a>
        <div className={styles.wrapper}>
          <header id="main-nav" className={styles['main-head']}>
            <Nav
              logo={
                <Link to="/">
                  <Logo className={styles.logo} alt="NothingToIt logo" />
                </Link>
              }
            >
              {authenticated && <Nav.Item onClick={handleLogout}>Log Out</Nav.Item>}
            </Nav>
          </header>
          <aside id="left-sidebar" className={styles['left-side']}></aside>
          <Grid
            container
            component="main"
            id="main-content"
            className={styles.content}
          >
            <Routes />
          </Grid>
          <aside id="right-sidebar" className={styles['right-side']}></aside>
          <footer id="footer" className={styles['main-footer']}></footer>
        </div>
      </>
    )
  )
}

export default App
