import { logError } from '../sentry/sentry'

const onError = (error, componentStack) => {
  let errorInfo = { componentStack }

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    errorInfo = error
    // API errors
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url
  }

  logError(error, errorInfo)
  throw error
}

export default onError
